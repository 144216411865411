jQuery(document).ready(function () {
	jQuery('a.fancybox').fancybox({
		beforeLoad: function () {
			var $element = $(this.element), title = $element.data('title'), description = $element.data('description');
			if (title) {
				this.title = '<strong>' + title + '</strong>';
			}
			if (description) {
				if (this.title) {
					this.title += '<br />';
				}
				this.title += description;
			}
		}, helpers: {title: {type: 'inside'}}
	});
	jQuery('.fancybox-iframe').on('click', function (e) {
		e.preventDefault();
		jQuery.fancybox.open({type: 'iframe', maxWidth: 800, maxHeight: 600, href: jQuery(this).attr('href')});
	});
});